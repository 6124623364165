@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  word-wrap: break-word;
}

body {
  font-family: "Inter", sans-serif;
  @apply text-black-4;
}

body,
button,
html,
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  @apply transition-colors duration-150 ease-in-out;
}

.height-100-minus-h-32 {
  height: calc(100% - 8rem);
}
